// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";
import React, { Fragment, useEffect, useMemo } from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
    OutcomeDetails as _OutcomeDetails,
} from "@components";
import { useOutcomeDetails, usePermission, useUploadDocument } from "@hooks";

// Types
import type { Location, Permissions } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    outcomeId: string;
}

/**
 * Finance Details
 */
const FinanceDetails = ({ location, outcomeId }: Props) => {
    /**
     * Find type
     */
    const type: { label: string | ""; path: string | "" } = useMemo(() => {
        if (!location) return { path: "", label: "" };
        return location.pathname.includes("/credit-note-details/")
            ? {
                  label: "credit note",
                  path: "/dashboard/finances/credit-notes/",
              }
            : {
                  label: "invoice release",
                  path: "/dashboard/finances/invoice-release/",
              };
    }, [location]);

    /**
     * Permissions
     */
    const { hasPermissionToEditOutcome }: Permissions = usePermission();

    //API
    const {
        getOutcomeDetails,
        loading,
        response,
        error: getFinancialDetailsError,
    } = useOutcomeDetails(type?.label);

    // Fetch evidences
    const {
        loading: { downloading: downloadingEvidence },
        download: downloadEvidence,
    } = useUploadDocument();

    /**
     * Fetch outcome details
     */
    useEffect(() => {
        if (!outcomeId) return;
        getOutcomeDetails(outcomeId);
    }, [outcomeId]);

    const data = useMemo(() => {
        if (!loading && !response?.data) return;
        return response?.data;
    }, [response?.data, loading]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`financial-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={loading}
                    error={getFinancialDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`financial-details-stepper`}
                                title={`${type?.label} details`}
                                subTitle={data?.contractRef}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <_OutcomeDetails
                                        outcome={data}
                                        id="outcome-details"
                                        downloadEvidence={{
                                            canDownload:
                                                hasPermissionToEditOutcome,
                                            onDownload: (
                                                contractId: string,
                                                evidenceId: string,
                                            ) =>
                                                downloadEvidence(
                                                    contractId,
                                                    evidenceId,
                                                ),

                                            loading: downloadingEvidence,
                                        }}
                                    />
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`financial-details-step-actions`}
                                        primaryButton={{
                                            text: "Return to overview",
                                            action: () => navigate(type?.path),
                                            isSubmitButton: true,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default FinanceDetails;
