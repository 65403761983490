// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import { DateTime } from "luxon";
import Grid from "@mui/material/Grid";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import { FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";

// Utils
import { convertDateForAPI, datePlaceholder } from "@utils";

// Own components
import { Modal } from "@components";

/**
 * Check date validation
 */
const checkDateValidation = (
    periodFrom: string,
    periodTo: string,
    contractStartDate: string,
    contractEndDate: string,
    nextAllowedStartDate: string,
) => {
    if (!contractStartDate || !contractEndDate)
        return { invalidStartDate: true, invalidDateRange: true };

    const luxonRangeStart = DateTime.fromISO(periodFrom);
    const luxonRangeEnd = DateTime.fromISO(periodTo);
    const luxonContractStartDate = DateTime.fromISO(contractStartDate);
    const luxonContractEndDate = DateTime.fromISO(contractEndDate);
    const luxonNextAllowedStartDate = DateTime.fromISO(nextAllowedStartDate); // old + 1

    const invalidStartDate =
        luxonRangeStart < luxonContractStartDate ||
        luxonRangeStart > luxonContractEndDate ||
        luxonContractEndDate < luxonRangeEnd;

    const isOverlapping = !nextAllowedStartDate
        ? true
        : luxonRangeStart < luxonNextAllowedStartDate;
    const invalidDateRange = luxonRangeEnd <= luxonRangeStart;

    return { invalidStartDate, invalidDateRange, isOverlapping };
};

/**
 * Props type
 */
type Props = {
    id: string;
    open: boolean;
    onClose: () => void;
    onSubmit: (periodFrom: string, periodTo: string) => void;
    scaleDateRange: { periodFrom: string; periodTo: string };
    validateAllowedScaleRange: { periodFrom: string; periodTo: string };
};

/**
 * Advanced Product scales
 */
const AdvancedProductScales = ({
    id,
    scaleDateRange,
    open,
    onSubmit,
    onClose,
    validateAllowedScaleRange,
}: Props) => {
    // Scale state
    const [period, setPeriod] = useState<{
        periodFrom: string;
        periodTo: string;
    }>({ periodFrom: "", periodTo: "" });

    // touched
    const [touched, setTouched] = useState({});
    const [error, setError] = useState("");

    /**
     * Handle blur
     */
    const onBlur = (key: string) => {
        const copyTouched = { ...touched };
        if (copyTouched[key]) return;
        copyTouched[key] = true;
        setTouched(copyTouched);
    };

    useEffect(() => {
        if (open) {
            setPeriod({
                periodFrom: validateAllowedScaleRange.periodFrom,
                periodTo: validateAllowedScaleRange.periodTo,
            });
        }
        setTouched({});
        setError("");
    }, [open]);

    /**
     * Change handler
     */
    const handleSubmit = () => {
        const { invalidStartDate, invalidDateRange, isOverlapping } =
            checkDateValidation(
                period?.periodFrom,
                period?.periodTo,
                scaleDateRange.periodFrom,
                scaleDateRange.periodTo,
                validateAllowedScaleRange.periodFrom,
            );

        if (invalidStartDate) {
            setError("Date range must fall within the contract date range.");

            return;
        }

        if (invalidDateRange) {
            setError("[Period to] date must be later than [Period from] date");
            return;
        }

        if (isOverlapping) {
            setError(
                "Date range must not overlap with the next allowed scale range.",
            );
            return;
        }

        onSubmit(period?.periodFrom, period?.periodTo);
        setPeriod({ periodFrom: "", periodTo: "" });
    };

    return (
        <Modal
            open={open}
            id={`product-scale-modal`}
            title={"Add scale period"}
            smallView
            onClose={() => {
                setTouched({});
                setError("");
                onClose();
            }}
            primaryButton={{
                action: () => handleSubmit(),
                text: "Save",
                disabled: !period?.periodFrom || !period?.periodTo,
            }}
            secondaryButton={{
                action: () => {
                    setTouched({});
                    setError("");
                    onClose();
                },
                text: "Cancel",
            }}
        >
            <Grid container item xs={12} px={"24px"} spacing={1}>
                <Grid item xs={6} pb={1}>
                    <InputLabel
                        id={`${id}-${"period?.periodFrom-label"}`}
                        shrink
                        error={
                            (touched["periodFrom"] && !period?.periodFrom) ||
                            error
                        }
                    >
                        {"Scale period from (*)"}
                    </InputLabel>
                    <DatePicker
                        value={datePlaceholder(period?.periodFrom)}
                        onChange={value => {
                            if (!period || !value || !value?.isValid) {
                                setPeriod(prev => ({
                                    ...prev,
                                    periodFrom: "",
                                }));
                                return;
                            }

                            if (value) {
                                setPeriod(prev => ({
                                    ...prev,
                                    periodFrom: convertDateForAPI(value),
                                }));
                            }
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: {
                                    svg: { color: "#036", opacity: "0.5" },
                                },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    (touched["periodFrom"] &&
                                        !period?.periodFrom) ||
                                    error,
                                id: `${id}-${"periodFrom"}`,
                                name: "periodFrom",
                                onBlur: () => onBlur("periodFrom"),
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                </Grid>

                <Grid item xs={6} pb={1}>
                    <InputLabel
                        id={`${id}-${"periodTo-label"}`}
                        shrink
                        error={
                            (touched["periodTo"] && !period?.periodFrom) ||
                            error
                        }
                    >
                        {"Scale period To (*)"}
                    </InputLabel>
                    <DatePicker
                        value={datePlaceholder(period?.periodTo)}
                        onChange={value => {
                            if (!value || !value?.isValid) {
                                setPeriod(prev => ({ ...prev, periodTo: "" }));
                                return;
                            }

                            if (value) {
                                setPeriod(prev => ({
                                    ...prev,
                                    periodTo: convertDateForAPI(value),
                                }));
                            }
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: {
                                    svg: { color: "#036", opacity: "0.5" },
                                },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    (touched["periodTo"] &&
                                        !period?.periodTo) ||
                                    error,
                                id: `${id}-${"periodTo"}`,
                                name: "periodTo",
                                onBlur: () => onBlur("periodTo"),
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                </Grid>

                {!!error && (
                    <Grid item xs={12}>
                        <FormHelperText error>{error}</FormHelperText>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default React.memo(AdvancedProductScales);
