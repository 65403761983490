import { Scale } from "@types";
import { thousandsSeparator, isArrayWithContent } from "@utils";

type Product = {
    contractPrice: null | string;
    currency: string;
    discountPercentage: null | string;
    externalProductCode: string | undefined;
    priceCondition: { price: string };
    productId: string;
    productName: string;
    sapSkuNo: string;
    scales: Array<Scale>;
};

export const getContractSubheader = (
    product: Product & {
        product?: { sapSkuNo?: string };
        type?: "discountPercentage" | "contractPrice" | "scale";
        amount?: number;
        priceSource?: string;
        priceCondition: string | { priceCondition: string; price: string };
    },
    currency: string,
) => {
    const sapSkuNo = product?.sapSkuNo || product?.product?.sapSkuNo;
    const priceCondition =
        typeof product?.priceCondition === "string"
            ? product?.priceCondition
            : product?.priceCondition?.priceCondition;

    const price = product?.priceCondition?.price
        ? `${thousandsSeparator(product?.priceCondition?.price)} ${currency}`
        : "";
    const priceSource = product?.priceSource;

    const discount =
        product?.type === "discountPercentage" || !!product?.discountPercentage
            ? `Fixed discount: ${thousandsSeparator(product?.discountPercentage || product?.amount)}%`
            : "";

    const contractPrice =
        product?.type === "contractPrice" || !!product?.contractPrice
            ? `Fixed price: ${thousandsSeparator(product?.contractPrice || product?.amount)} ${currency}`
            : "";

    const scales =
        product?.type === "scale" || isArrayWithContent(product.scales)
            ? "Scales"
            : "";

    return [
        sapSkuNo,
        priceCondition || priceSource,
        price,
        discount || contractPrice || scales,
    ];
};
